<template>
  <v-app class="admin-layout">
    <v-navigation-drawer v-model="drawer" app color="grey darken-3" dark>
      <router-link :to="{ name: 'home' }">
        <v-img src="@/assets/logo.svg" class="ma-8" />
      </router-link>
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="white">
            <v-icon color="primary">person</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          @click.prevent="goTo(item)"
          :to="item.link"
          :exact="item.exact"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item link @click.prevent="signOut()">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Çıkış</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevate-on-scroll>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">{{ title }}</span>
      </v-toolbar-title>
    </v-app-bar>

    <v-main class="content">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    title: "",
    currentMenuItem: {},
    menuItems: [
      // {
      //   icon: "dashboard",
      //   title: "Giriş",
      //   link: {
      //     name: "admin-home"
      //   },
      //   exact: true
      // },
      {
        icon: "important_devices",
        title: "Eğitimler",
        link: {
          name: "admin-courses"
        },
        exact: false
      },
      {
        icon: "mdi-shopping",
        title: "Siparişler",
        link: {
          name: "admin-orders"
        },
        exact: false
      },
      {
        icon: "people",
        title: "Kullanıcılar",
        link: {
          name: "admin-users"
        },
        exact: false
      },
      {
        icon: "label",
        title: "Kategoriler",
        link: {
          name: "admin-categories"
        },
        exact: false
      }
    ]
  }),
  computed: {
    userSignedIn() {
      return this.$store.getters["auth/userSignedIn"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch("auth/signOut");
      this.$router.push({ name: "home" }).catch(() => {
        return false;
      });
    },
    goTo(item) {
      this.currentMenuItem = item;
      this.title = item.title;
      this.$router.push(item.link).catch(() => {
        return false;
      });
    }
  },
  mounted() {
    this.currentMenuItem = this.menuItems[0];
    this.title = this.currentMenuItem.title;
  }
};
</script>

<style lang="scss" scoped>
.admin-layout {
  .content {
    background-image: url("../../assets/bg-waves.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }
}
</style>

<style lang="scss" scoped></style>
